<template>
  <div>
    <slot>
      <h1 class="info-page__title">
        {{ title }}
      </h1>
      <p class="info-page__description">
        {{ description }}
      </p>
    </slot>
  </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      default: 'Choose product',
    },
    description: {
      type: String,
      default: 'This page has specific product information. Please choose a product in the menu.',
    },
  },

};
</script>

<style scoped>
  .info-page__title {
    margin: 2.25rem 0 0.5rem 0;
    font-size: var(--font-size-800);
    font-weight: var(--font-weight-bold);
  }

  .info-page__description {
    font-size: var(--font-size-75);
    font-weight: var(--font-weight-regular);
  }
</style>

<template>
  <div class="users grid grid-view-layout">
    <page-header />
    <info-page
      title="Hierarchies"
      description="Under construction."
    />
  </div>
</template>

<script>
import PageHeader from '@/components/domain/page-header.vue';
import InfoPage from '@/components/shared/info-page.vue';

export default {
  components: {
    PageHeader,
    InfoPage,
  },

  setup() {
    return {
      PageHeader,
    };
  },
};
</script>
